/**
 * @notice 用户联系人管理
 * @author hhq
 */
import http from "../http"

//新增联系人
export const addOne=(params)=>{
    return http.postRegister('/app/tUser/liaison/add',params)
}
//删除联系人
export const delOne=(params)=>{
    return http.postRegister('/app/tUser/liaison/del',params)
}
//获取默认联系人
export const getDefault=(params)=>{
    return http.postRequest('/app/tUser/liaison/getDefaultLiaison',params)
}
//获取联系人列表
export const getCList=(params)=>{
    return http.postRegister('/app/tUser/liaison/getList',params)
}
//更新联系人信息
export const toUpdate=(params)=>{
    return http.postRegister('/app/tUser/liaison/update',params)
}
