<template>
  <div class="index-container">
    <div class="bread">
     <span @click="home()">操作台 / </span>
      <span class="gray">车辆出售</span>
    </div>
    <el-row class="table-body">
      <div class="line clearfix">
        <h5 class="fl title">车辆出售管理</h5>
        <div class="fr search">
          <el-input size="small" type="text" placeholder="请输入车牌号" v-model="keywords">
            <template slot="prefix">
              <el-button icon="el-icon-search" type="text" disabled :model="head"></el-button>
            </template>
          </el-input>
          <!--          <el-input size="small" type="text" placeholder="请选择负责人"></el-input>-->
          <el-button type="primary" size="small" @click="getList">查询</el-button>
          <!--          <el-button type="primary" size="small" plain @click="addNew">新增</el-button>-->
        </div>
      </div>
      <div class="list">
        <el-tabs v-model="sellState" @tab-click="handleClick">
          <el-tab-pane label="未出售" name="4"></el-tab-pane>
          <el-tab-pane label="出售中" name="5"></el-tab-pane>
          <el-tab-pane label="已出售" name="6"></el-tab-pane>
        </el-tabs>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column fixed type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="carNum" label="车牌号" width="300"></el-table-column>
          <!--          <el-table-column prop="carMode" label="品牌型号" min-width="120"></el-table-column>-->
          <el-table-column
              prop="carBrand"
              label="品牌"
              min-width="120">
          </el-table-column>
          <el-table-column
              prop="carMode"
              label="型号"
              min-width="120">
          </el-table-column>
          <el-table-column prop="type" label="车辆状态" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.type === 4" class="red">未出售</span>
              <span v-if="scope.row.type === 5" class="red">出售中</span>
              <span v-if="scope.row.type === 6" class="red">已出售</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="220">
            <template slot-scope="scope">
              <el-button type="text" size="small"
                         @click="HandleDetail(scope.row)"
                         v-if="showRouter('admin')"
              >查看详情
              </el-button>
              <el-button @click="getDetail(scope.row)" type="text"
                         size="small"
                         v-if="scope.row.type===4 || scope.row.type===1"
              >出售
              </el-button>

              <el-button type="text" size="small"
                         @click="HandleCanCel(scope.row)"
                         v-if="scope.row.type===5 && showRouter('admin')"
              >取消出售
              </el-button>
              <el-button type="text" size="small"
                         @click="handleCarOver(scope.row)"
                         v-if="scope.row.type===5 && showRouter('webapp_tcarinfo_edit')"
              >完成出售
              </el-button>
              <el-button type="text" size="small"
                         @click="handleDelCar(scope.row)"
                         v-if="scope.row.type===4 || scope.row.type===1 && showRouter('admin')"
              >删除
              </el-button>
              <el-button type="text" size="small"
                         @click="HandleCanCel(scope.row)"
                         v-if="scope.row.type===6 && showRouter('admin')"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pages">
        <el-pagination
            background
            :hide-on-single-page="showPage"
            layout="prev, pager, next"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :total="total"
        ></el-pagination>
      </div>
    </el-row>
    <edit-sell ref="sell" @save="saveShell"></edit-sell>
  </div>
</template>

<script>
import EditSell from "@/components/EditSell";
import {delOne, downUp, getList, over as carOver,sale} from '@/utils/api/car';

export default {
  name: "clcs",
  components: {
    EditSell,
  },
  data() {
    return {
      total: 0, //总条数
      pageNo: 1,
      pageSize: 10,
      showPage: true,
      sellState: "4",
      tableData: [
      ], //表格数据
      plate: "", //搜索车牌
      head: "", //负责人
      editOne: "", //编辑数据
      keywords: ""  //搜索关键字
    };
  },
  methods: {
    home(){
      this.goHome()
    },
    showRouter(permission) {
      if (permission === "admin") {
        return true;
      } else {
        let result = this.$store.state.permissions.findIndex(
            item => item === permission
        );
        if (result !== -1) {
          return true;
        } else {
          return false;
        }
      }
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getList()
    },
    handleClick(e) {
      this.getList()
    },
    addNew() {
      this.$refs["sell"].showEdit();
    },
    getDetail(row) {
      this.$refs["sell"].showEdit(row);
    },
    //获取车辆出售列表
    getList() {
      let data = {
        type: this.sellState,
        rows: this.pageSize,
        // totalPage:this.pageNo,
        page: this.pageNo,
      };
      if(this.sellState === 4){
        this.data.type = 7
      }
      if (this.keywords !== "") {
        data.carNum = this.keywords;
      }
      getList(data).then(res => {
        if (res.code === 0) {
          this.tableData = res.data.list;
          this.total = Number(res.data.total)
        }
      });
    },
    //删除车辆
    handleDelCar(row) {
      this.$confirm('此操作将删除该车辆, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // console.log(row)
        let data = {
          // type: "1",
          id: row.id
        }
        delOne(data).then(res => {
          if (res.data === 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },
    HandleDetail(row){
      // this.$refs['detail'].showEdit(row)
      this.$router.push({name:'出售详情',params: {id:row.id,state:this.sellState}})
    },
    //车辆取消出售
    HandleCanCel(row) {
      this.$confirm('此操作将取消销售该车辆, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          type: "4",
          id: row.id
        }
        downUp(data).then(res => {
          if (res.code === 0) {
            this.getList()
            this.$message({
              type: 'success',
              message: '取消出售成功!'
            });

          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
      }).catch(() => {
      });
    },
    // 出售完成
    handleCarOver(row) {
      this.$confirm('该车辆是否已经售出呢, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          type: "6",
          id: row.id
        }
        carOver(data).then(res => {
          if (res.code === 0) {
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
      }).catch(() => {
      });
    },
    saveShell(e){
      sale(e).then(res=>{
        if (res.code==0){
          this.$baseMessage('设置出售成功','success')
          setTimeout(() => {
            this.pageNo=1
            this.getList()
          }, 200);
        }else{
          this.$baseMessage(res.msg,'warning')
        }
      })
    }
  },
  created() {
    let sellState = this.$route.query.sellState
    this.sellState = sellState ? String(sellState) : "4"
    this.getList()

  }
};
</script>

<style  lang="less">
.table-body {
  background-color: #ffffff;
  padding: 30px;
  padding-top: 0;

  .line {
    height: 90px;
    line-height: 90px;

    .title {
      font-size: 16px;
      font-weight: 800;
      color: #333333;
    }

    .search {
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: flex-end;

      .el-input {
        font-size: 12px;
        margin-right: 12px;

        .el-input__inner {
          border: none;
          background-color: #fafafa;
          padding-left: 50px;

          &:last-child {
            padding-left: 15px;
          }
        }

        .el-input__prefix {
          width: 40px;

          &:after {
            content: "";
            width: 1px;
            height: 15px;
            background-color: #d8d7d7;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
</style>
