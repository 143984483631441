<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="45%">
    <el-form ref="form"  :model="formAdd" label-position="right" :rules="ruleSell" class="info">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="车牌号" :label-width="formLabelWidth" prop="carNum">
            <el-input v-model="formAdd.carNum" autocomplete="off" placeholder="请输入车牌号码" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="过户次数" :label-width="formLabelWidth" prop="transferCount">
            <el-select v-model="formAdd.transferCount" placeholder="请选择过户次数" filterable >
              <el-option
                  v-for="index of 100"
                  :key="index"
                  :label="index"
                  :value="index">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系人" :label-width="formLabelWidth" prop="liaisonName">
            <el-select v-model="formAdd.liaisonName"
                       @change="handleLiaisonName"
                       placeholder="请选择联系人"
                       filterable >
              <el-option
                  v-for="item in cList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话" :label-width="formLabelWidth" prop="liaisonPhone">
            <el-input v-model="formAdd.liaisonPhone" autocomplete="off" placeholder="请输入联系电话" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="负责人" :label-width="formLabelWidth" prop="userIdSale">
            <el-select v-model="formAdd.userNameSale"
                       @change="handleFuzeren"
                       placeholder="请选择负责人"
                       filterable >
              <el-option
                  v-for="item in hList"
                  :key="item.id"
                  :label="item.realName"
                  :value="item.realName">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="负责人电话" :label-width="formLabelWidth" prop="userNameSalePhone">
            <el-input v-model="formAdd.userNameSalePhone" autocomplete="off" placeholder="请输入负责人电话" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="全款总额" :label-width="formLabelWidth" prop="priceSum"  >
            <el-input v-model="formAdd.priceSum" autocomplete="off" placeholder="请输入全款总额"
                      maxlength="7">
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form ref="form"  :model="formAdd" label-position="right" :rules="ruleSell" class="s-raido">
      <el-row :gutter="20">
        <el-row>
          <el-col :span="24">
            <el-form-item label="支付方式" :label-width="formLabelWidth" prop="payWay">
              <el-radio-group v-model="formAdd.payWay">
                <el-radio :label="2">分期</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" >
            <el-form-item label="首付" :label-width="formLabelWidth" prop="firstPay"  >
              <el-input v-model="formAdd.firstPay" autocomplete="off" placeholder="请输入首付"
                        maxlength="7">
                <span slot="append">元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" >
            <el-form-item label="月供" :label-width="formLabelWidth" prop="monthPay" >
              <el-input v-model="formAdd.monthPay" autocomplete="off" placeholder="请输入月供"
                        maxlength="7">
                <span slot="append">元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" >
            <el-form-item label="分期" :label-width="formLabelWidth" prop="payCount"  >
              <el-input v-model="formAdd.payCount" autocomplete="off" placeholder="请输入期数"
                        maxlength="3">
                <span slot="append">期</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" >
            <el-form-item label="分期总额" :label-width="formLabelWidth" prop="moneyAll" >
              <el-input v-model="moneyAll" autocomplete="off" placeholder="自动汇总"
                        maxlength="7">
                <span slot="append">元</span>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="使用年限" :label-width="formLabelWidth" prop="useYear">
              <el-date-picker
                  v-model="formAdd.useYear"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-col :span="24">
          <el-form-item label="备注" :label-width="formLabelWidth" prop="remarkSale">
            <el-input
                type="textarea"
                rows="6"
                v-model="formAdd.remarkSale"
                maxlength="200"
                show-word-limit
                resize="none">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </div>
  </el-dialog>

</template>

<script>
import {getList, sale as addCarSale} from "@/utils/api/car";
import {getCList as getContactList} from '@/utils/api/contact'
import {getResponsible} from '@/utils/api/dict'

export default {
  name: "EditSell",
  data(){
    var checkPhone = (rule, value, callback)=>{
      let reg_mobile =/^1[3456789]\d{9}$/
      let reg_phone =/^(0d{2,3})-?(d{7,8})$/
      if (!reg_mobile.test(value) || !reg_phone.test(value)){
        callback(new Error('请输入正确的手机或座机号码'));
      }else{
        callback();
      }
    }
    return{
      dialogFormVisible:false,
      title:'新增',
      formLabelWidth:"100px",
      labelPosition:"right",
      pList:[],//车牌列表
      cList:[],//联系人列表
      hList:[],//负责人列表
      formAdd:{
        cid:'',
        carNum:"",//车牌
        priceSum:"",//全款
        transferCount:"",//过户次数
        liaisonName:"",//联系人
        liaisonPhone:"",//联系人电话
        userIdSale:'',//负责人ID
        userNameSale:'',//负责人姓名
        userNameSalePhone:"",//负责人电话
        payWay:2,//支付方式
        money:'',//总额
        firstPay:"",//首付
        monthPay:"",//月供
        payCount:"",//期数
        moneyAll:"",//分期汇总
        useYear:"",//使用年限
        remarkSale:'',//备注
      },
      ruleSell:{
        carNum: [
          { required: true, message: '请选择车牌号', trigger: 'blur' },
        ],
        transferCount: [
          { required: true, message: '请选择过户次数', trigger: 'blur' },
        ],
        liaisonName: [
          { required: true, message: '请选择联系人', trigger: 'blur' },
        ],
        liaisonPhone: [
          { required: true, message: '请填写联系电话', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        userNameSale: [
          { required: true, message: '请选择负责人姓名', trigger: 'blur' },
        ],
        userNameSalePhone: [
          { required: true, message: '请选择负责人手机号', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        userIdSale:[
          { required: true, message: '缺少联系人信息', trigger: 'blur' },
        ],
        payWay: [
          { required: true, message: '请选择支付方式', trigger: 'blur' },
        ],
        saleYearCheckUpFlag: [
          { required: true, message: '请选择是否有年间', trigger: 'blur' },
        ],
        saleInsureTime: [
          { required: true, message: '请选择是否有保险', trigger: 'blur' },
        ],
        ascription: [
          { required: true, message: '请选择车辆归属', trigger: 'blur' },
        ],
        remarkSale: [
          { required: true, message: '请填车辆销售', trigger: 'blur' },
        ],
        money:[
          { required: true, message: '请输入全款金额', trigger: 'blur' },
        ],
        firstPay: [
          { required: true, message: '请输入首付金额', trigger: 'blur' },
        ],
        payCount: [
          { required: true, message: '请输入分期期数', trigger: 'blur' },
        ],
        monthPay: [
          { required: true, message: '请输入月供金额', trigger: 'blur' },
        ],
        moneyAll: [
          { required: true, message: '缺少金额汇总', trigger: 'blur' },
        ],
      }
    }
  },
  computed: {
    moneyAll(){
      let date = this.formAdd.firstPay*1+((this.formAdd.monthPay*1)*(this.formAdd.payCount*1))
      return date
    }
  },
  watch:{
    'formAdd.moneyAll':function (){
      this.formAdd.moneyAll=this.moneyAll
    }
  },
  methods:{
    showEdit(row) {
      if (!row) {
        this.title = '新增车辆出售信息'
      } else {
        this.title = '编辑车辆出售信息'
        this.formAdd = Object.assign({},this.formAdd, row)
      }
      this.dialogFormVisible = true
    },
    close(){
      this.$refs["form"].resetFields();
      this.formAdd = this.$options.data().form
      this.dialogFormVisible = false
      this.$emit('close')
    },
    save(){
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          // const { msg } = await doEdit(this.form)
          // this.$baseMessage(msg, 'success')
          // this.$refs['form'].resetFields()
          // this.dialogFormVisible = false
          this.$emit('save',this.formAdd)
          this.$refs['form'].resetFields()
          this.dialogFormVisible = false
          this.formAdd = this.$options.data().form
        } else {
          return false
        }
      })
    },
    //获取联系人
    getContactList(){
      // this.cList
      getContactList().then(res => {
        if(res.code ===0 ){
          this.cList =  res.data
        }
      })
    },
    //获取负责人
    getResponsible(){
      getResponsible().then(res => {
        if(res.code === 0){
          this.hList = res.data
        }
      })
    },
    //选择联系人时绑定电话
    handleLiaisonName(value) {
      this.cList.map(item => {
        if(item.name === value){
          this.formAdd.liaisonPhone = item.telePhone
        }
      })
    },
    //选择负责人时绑定电话
    handleFuzeren(value) {
      this.hList.map(item => {
        if(item.realName === value){
          this.formAdd.userNameSalePhone = item.userPhone
          this.formAdd.userNameSaleUserId = item.id
          this.formAdd.liaisonNameSale = value
        }
      })
    },
    //新增
    handleSave(){
      let data = this.formAdd
      addCarSale(data).then(res => {
        if(res.code === 0){
          this.close()
          this.$emit('getList')
        }
      })
    }
  },
  created() {
    this.getContactList()
    this.getResponsible()
  },
}
</script>

<style  lang="less" scoped>

.el-dialog__body{
  .el-input{
    width: 100%;
  }
}
.el-select{
  width: 100%;
}
.s-radio{
  margin-top: 30px;
}
</style>
