/**
 * @notice 车辆管理
 * @author hhq
 */
import http from "../http"
//新增车辆
export const addOne =(params)=>{
    return http.postRegister('/car/add',params)
}
export const getCarModeSelectVo =(params)=>{
    return http.postRequest('/app/tCarBrand/getCarModeSelectVo',params)
}
//车辆下架
export const downUp =(params)=>{
    return http.postRequest('/car/car/downUp',params)
}
//车辆上架
export const putUp =(params)=>{
    return http.postRequest('/car/car/putUp',params)
}
//新增出售
export const sale =(params)=>{
    return http.postRegister('/car/car/sale',params)
}
//删除车辆
export const delOne =(params)=>{
    return http.postRequest('/car/delete',params)
}
//查看车辆详情
export const detailOne =(params)=>{
    return http.postUrlParams('/car/detail',params)
}
//空闲中的车辆删除
export const kxDelete =(params)=>{
    return http.postRegister('/car/kxDelete',params)
}
//获取车辆列表
export const getList =(params)=>{
    return http.postRegister('/car/list',params)
}
//已完成车辆
export const over =(params)=>{
    return http.postRegister('/car/over',params)
}
//编辑空闲中
export const updateKxCar =(params)=>{
    return http.postRegister('/car/updateKxCar',params)
}
/*批量操作*/
//保存批量信息
export const batchSubmit =(params)=>{
    return http.postRegister('/app/car/upload/car/submit',params)
}
//删除提交的Excel全部数据
export const delAllExceLCarInfo =(params)=>{
    return http.postRegister('/app/car/upload/delAllExceLCarInfo',params)
}
//删除一条Excel数据
export const delExceLCarInfo =(params)=>{
    return http.postRegister('/app/car/upload/delExceLCarInfo',params)
}
//修改本次上传的车辆信息
export const editExceLCarInfo =(params)=>{
    return http.postRegister('/app/car/upload/editExceLCarInfo',params)
}
//返回按钮是否禁用
export const getBtnFlag =(params)=>{
    return http.postRegister('/app/car/upload/getBtnFlag',params)
}
//查看本次版本信息
export const getExceByOneVersion =(params)=>{
    return http.postRegister('/app/car/upload/getExceByOneVersion',params)
}
//查看全部版本信息
export const getExceByVersion =(params)=>{
    return http.postRegister('/app/car/upload/getExceByVersion',params)
}
//获取教学链接
export const getLjUrl =(params)=>{
    return http.postRegister('/app/car/upload/getLjUrl',params)
}
//ocr读取行驶证
export const toOcr =(params)=>{
    return http.postRegister('/app/car/upload/ocr',params)
}
//修改本次单个状态未正常
export const updateSuccess =(params)=>{
    return http.postRegister('/app/car/upload/updateSuccess',params)
}
//批量上传图片
export const uploadCarImag =(params)=>{
    return http.postRegister('/app/car/upload/uploadCarImag',params)
}
//上传一张图片
export const uploadCarImagOne =(params)=>{
    return http.postRegister('/app/car/upload/uploadCarImagOne',params)
}
//导入Excel信息
export const uploadExcel =(params)=>{
    return http.postRegister('/app/car/upload/uploadExcel',params)
}
//年检记录
export const getInspection =(params)=>{
    return http.getRequest('/tyearlyinspection/remindList',params)
}
//新增年检记录
export const addInspection =(params)=>{
    return http.postRegister('/tyearlyinspection/add',params)
}
//编辑年检记录
export const editInspection =(params)=>{
    return http.postRegister('/tyearlyinspection/update',params)
}
//编辑年检记录
export const delInspection =(params)=>{
    return http.getRequest('/tyearlyinspection/del',params)
}
//年检记录详情
export const detailInspection =(params)=>{
    return http.getRequest('/tyearlyinspection',params)
}
//维修记录
export const getMaintain = (params)=>{
    return http.getRequest('/trepair/page',params)
}
//新增维修记录
export const addMaintain = (params)=>{
    return http.postRegister('/trepair/add',params)
}
//编辑维修记录
export const editMaintain = (params)=>{
    return http.postRegister('/trepair/update',params)
}
//删除维修记录
export const deleteMaintain = (params)=>{
    return http.getRequest('/trepair/del',params)
}
//维修记录详情
export const detailMaintain = (params)=>{
    return http.getRequest('/trepair',params)
}
//保养记录
export const getKeep = (params)=>{
    return http.getRequest('/tmaintain/page',params)
}
//新增保养记录
export const addKeep = (params)=>{
    return http.postRegister('/tmaintain/add',params)
}
//编辑保养记录
export const editKeep = (params)=>{
    return http.postRegister('/tmaintain/update',params)
}
//删除保养记录
export const deleteKeep = (params)=>{
    return http.getRequest('/tmaintain/del',params)
}
//保养记录详情
export const detailKeep = (params)=>{
    return http.getRequest('/tmaintain',params)
}
